.btnLanguageSelectionContainer {
    margin: 0 10px;
    padding-top: 10px;
    max-width: 150px;
    position: absolute !important;
    right: 50px;
}

.btnLanguageSelectionContainer button {
    color: #000066;
    border: thin solid #000066;
    padding: 0 10px;
}

.btnLanguageSelectionContainer button::after {
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000066;
}

.btnLanguageSelectionContainer button[aria-expanded="true"]:after {
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #4d4d4d;
}
