@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap);
* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';

}

html, body {
    background-color: aliceblue;
    -webkit-font-smoothing: antialiased !important;
}


.gmc-label {
    font-weight: bold;
    color: #000066;
    font-size: 14px;
}

.gmc-input {
    margin-top: 5px;
    background-color: transparent;
    border: 1px rgba(0, 0, 102, 0.6) solid;
    border-radius: 8px;
    color: #000066;
    padding: 4px 4px 4px 10px;
    outline: none;
}

.btnDefault {
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    background-color: #cc0057;
    border: none;
    color: white;
}

.btnDefaultOutlined {
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    background-color: transparent;
    color: #000066;
    border: 1px solid #000066;
}

.btnDefaultOutlinedActive {
    background-color: #cc0057;
    color: #FFF;
    border: none;
}

.btnDefault:last-of-type {
    margin-left: 10px;
}

.btnLink {
    color: #000066;
    font-size: 16px;
    border: 1px solid #000066;
    border-radius: 20px;
    padding: 5px 15px;
}
.home {
    background-image: url(/static/media/background.c20dcc3a.png);
    height: 750px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 auto;
}

.content {
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.contentLeft {
    /*padding: 112px;*/
    padding: 112px 150px 112px 90px;
    color: white;
}

.contentLeft h1 {
    font-size: 45px;
}

.contentLeft p {
    margin-top: 20px;
    font-size: 15px;
    line-height: 20px;
    
}

.contentRight {
   /* padding: 112px; */
   text-align: center;
   margin-left: 30%;

}
.contentRight span {
    font-size: 18px;
    color: white;
}
.contentRight form {
    display: flex;
    flex-direction: column;
}

.contentRight form input {
    margin-top: 15px;
    width: 200px;
    height: 32px;
    background-color: transparent;
    border: 1px white solid;
    border-radius: 8px;
    color: white;
    padding: 4px 4px 4px 10px;
    outline: none;
}

::-webkit-input-placeholder {
    color: white;
}

:-ms-input-placeholder {
    color: white;
}

::placeholder {
    color: white;
}

.contentRight form button {
    margin-top: 15px;
    height: 28px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #cc0057;
    border: none;
    color: white;
    padding: 0px 40px;
}

.contentRight form button:hover {
    cursor: pointer;
    background-color: #cc3366;
}
.stores {
    margin-top: 30px;
    width: 430px;
    display: flex;
    justify-content: center;
    
    
    
}

.storeIconApple {
    width: 200px;
    
}

.storeIconGoogle {
    width: 187px;
    margin-right: 20px;
    
}

.haveAccount{
    display: none;
}

@media only screen and (max-width: 992px){
    

    .contentLeft{
        padding-left: 130px;
        padding-right: 10px;
    }
    .contentLeft h1{
        font-size: 2.5em;
    }

    .contentRight{
        padding-left: 50px;
    }

    .contentRight .formHome{
        height: 32px;
        width: 200px;
    }

    .contentRight button{
        width: 150px;
        align-self: center;
        
    }
}

@media only screen and (max-width: 768px){
    .home{
        height: 800px;
    }
    .content{
    
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
       
    }

    .content h1{
        font-size:2.4em;
        text-align: center;
        width: 100% ;
        
    }

    .content p{
        text-align: center;
    }

    .contentLeft{
        display: flex;
        flex-direction: column;
        padding: 10px 50px 10px 50px;
        margin-top: 10%;

    }

    .stores{
        align-self: center;
        margin-top: 10px;      
      
    }

    .contentLeft img{
        height: 45px ;        
        
    }
    

    .contentRight{
        display: flex;
        flex-direction: column;
        background-color: #0D2144;
        height: 350px;
        width: 100%;
        box-shadow: 20px -30px 20px 25px #0D2144;
        align-items: center;
        padding: 10px 50px 0px 10px;
        margin-top: 55px;
        
    }

    .contentRight .formHome{
        height: 22px;
        width: 260px;
    }

    .contentRight span{
        width: 100%;
        text-align: center;
    }

    .contentRight br {
        display: none;
    }

    .contentRight button{
        width: 125px;
        align-self: center;
    }

    .haveAccount{
        display: block;
        margin-top: 15px;
        margin-bottom: 100px;
        color: #fff;
    }

    .haveAccount a{
        color: #cc3366;
    }
}
@media only screen and (max-width: 600px){
    
    
    .content{
    
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
       
    }

    .content h1{
        font-size:2.1em;
        text-align: center;
        width: 100% ;
        
    }

    .contentLeft{
        display: flex;
        flex-direction: column;
        padding: 10px 50px 10px 50px;
        margin-top: 15%;

    }

    .stores{
        margin-top: 10px;
    }

    

    .contentRight{
        display: flex;
        flex-direction: column;
        background-color: #0D2144;
        height: 300px;
        box-shadow: 10px -25px 20px 20px #0D2144;
        align-items: center;
        padding: 10px 0px 0px 0px;
        margin-top: 55px;
    }

    .contentRight .formHome{
        height: 25px;
        width: 260px;
        font-size: 12px;
        
    }

    .contentRight span{
        width: 100%;
        text-align: center;
    }

    .contentRight br {
        display: none;
    }

    .contentRight button{
        width: 130px;
        height: 28px;
        align-self: center;
    }

    .haveAccount{
        margin-bottom: 80px;
    }
}
@media only screen and (max-width: 450px){
    .home{
        height: 800px;
    }
    
    .content{
    
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
       
    }

    .content br{
        display: none;
    }

    .content h1{
        font-size:1.5em;
        text-align: center;
        width: 100% ;
        
    }

    .contentLeft{
        display: flex;
        flex-direction: column;
        padding: 10px 70px 10px 30px;
        margin-top: 25%;

    }

    .contentLeft p {
        text-align: center;
        font-size: 1.1em;
    }

    .stores{
        margin-top: 10px;
        margin-left: 20px;
        width: 100%;
       
    }

    .stores img{
        margin: 0 0 0 20px;
        
    }

    
    .contentRight{
        display: flex;
        flex-direction: column;
        background-color: #0D2144;
        width: 100%;
        height: 255px;
        box-shadow: 20px -35px 20px 20px #0D2144;
        align-items: center;
        padding: 10px 0;
        margin-top: 55px;
    }

    .contentRight .formHome{
        height: 22px;
        width: 190px;
    }

    .contentRight span{
        width: 90%;
        font-size: 1em;
        text-align: center;
    }

    .contentRight br {
        display: none;
    }

    .contentRight button{
        width: 130px;
        align-self: center;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:375px){
    
    .contentLeft{

        width: 85%;
        margin-top: 18%;
        margin-bottom: 10%;
        margin-left: 10%;
        padding: 10px 0;
    }
    .stores{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        
    }

    .stores img{
        margin-bottom: 5px;
        margin-left: 0;
        margin-top: 15px;
    }


    .contentRight{
        height: 300px;
        margin: 25px 0 0;
        padding: 0;
        
        
    }

    .contentRight button{
        margin-bottom: 5px;
    }

    .contentRight form{
        height: 157px;
    }

    .haveAccount{
        display: block;
        margin-top: 5px;
        margin-bottom: 80px;
        color: #fff;
        
    }
}

@media only screen and (max-width:320px){
    .contentRight span{
        width: 310px;
    }

    .contentRight button{
        margin-bottom: 5px;
    }

    .contentRight form{
        height: 140px;
    }

    .contentRight{
        margin-top: 35px;
    }
}








.toggle-button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 15px;
    box-sizing: border-box;
    

}

.toggle-button:focus{
    outline: none;
}

.toggle-button__line{
    width: 30px;
    height: 3px;
    background: #fff;
    transition: all 0.4s ease;
}

/*.toggle-button__line {
    transform: rotate(135deg);
}*/
.side-drawer{
    height: 100%;
    background: #000066;
    box-shadow: 2px -20px 20px 1px #00006648;
    position: fixed;
    top: 100px;
    left: 0;
    width: 60%;
    max-width: 400px;
    z-index: 200;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.side-drawer.open{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.side-drawer ul{
    height: 80%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
    
}

.side-drawer li{
    margin: 1rem 2rem;
}

.side-drawer a{
    color: #fff;
    text-decoration: none;
    

}

.side-drawer a:hover,
.side-drawer a:active{
    color: #cc0057;
    font-weight: 700;
}

@media only screen and (max-width:425px){
    .side-drawer{
        width: 50%;
    }
}

@media only screen and (max-width:375px){
    .side-drawer{
        width: 60%;
    }
}

@media only screen and (min-width: 869px){
    .side-drawer{
        display: none;
    }
}




.backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 100;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
  
}
.contentHome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 50px;
    color: white;
    height: 76px;
}

.centerMenu {
    height: 40px;
    padding: 21px

}

#dropdownList li {
    list-style: none;
    float: left;
    position: relative;
}

#dropdownList li a {
    display: block;
    margin-left: 35px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 10px;

}


#dropdownList li ul {
    display: none;
    position: absolute;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 6px 6px 6px rgba(128, 128, 128, 0.103);
}

#dropdownList li ul li {
    width: 100px;
    height: 45px;
}


#dropdownList li ul li a {
    padding: 10px 0px 5px 0px;
    text-align: center;
    color: #000066;
    width: 150px;
    height: 30px;
    border-radius: 20px;
    margin: 0px;
}

#dropBox {
    margin-left: 20px;
    width: 150px;
}

#dropdownList li:hover ul {
    display: block;
}

#dropdownList li ul li :hover {
    background-color: #cc3366;
    color: white;
}


.rightMenu {
    padding: 16px;

}

.rightMenu select {
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 30px;
    border: none;
    color: white;
    font-weight: bold;
    background-color: transparent;
    text-decoration: underline;
}

.rightMenu a {
    margin-right: 10px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;

}

.btnRegister {
    width: 170px;
    height: 28px;
    border-radius: 20px;
    font-weight: bold;
    background-color: #cc0057;
    border: none;
    color: white;
}

.btnRegister:hover {
    cursor: pointer;
    background-color: #cc3366;
    color: white;

}

.contentSendRecieve {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px 50px;
    color: white;
    height: 130px;
    background-color: #000066;
}

#dropdownList :hover {
    color: #cc3366;
    font-size: 14px;
}

.rightMenu select:hover {
    cursor: pointer;
    color: #cc3366;
    font-size: 14px;
}

.rightMenu a:hover {
    cursor: pointer;
    color: #cc3366;
    font-size: 14px;
}

@media only screen and (max-width: 998px) {
    .contentSendRecieve {
        padding: 25px 15px;
    }

    .centerMenu {
        height: 40px;
        padding: 21px 5px;

    }

    .rightMenu {
        padding: 16px 2px;

    }

    .rightMenu select {
        width: 30px;
    }

    .btnRegister {
        width: 180px;

    }


}

@media only screen and (max-width: 911px) {
    .contentHome {
        padding: 25px 20px;
    }

    .rightMenu a {
        padding-right: 50px;
    }

    #dropdownList li a {
        margin-left: 10px;


    }
}

@media only screen and (max-width: 868px) {
    .centerMenu {
        display: none;
    }

    .rightMenu select {
        display: none;
    }

    .rightMenu a {
        display: none;
    }

    .contentSendRecieve {
        padding: 25px 50px;
    }

    .btnRegister {
        width: 170px;

    }

}

@media only screen and (max-width: 600px) {
    .centerMenu {
        display: none;
    }

    .rightMenu select {
        display: none;
    }

    .rightMenu a {
        display: none;
    }

    .contentSendRecieve {
        padding: 25px;
    }

    .contentHome {
        padding-left: 20px;
    }

    .leftMenu {
        padding-left: 0px;
        padding-right: 20px;
        padding-top: 15px;
    }
}

@media only screen and (max-width: 320px) {
    .contentHome {
        padding: 25px 5px;
    }

    .contentSendRecieve {
        padding: 25px 10px;
    }

    .leftMenu {
        width: 130px;
    }

    .btnRegister {
        width: 90px;
    }
}

@media only screen and (min-width: 869px) {
    .topbar__toggle-button {
        display: none;
    }
}

.btnLanguageSelection {
    margin: 0 10px;
    padding-top: 10px;
}

.btnLanguageSelection button {
    color: #FFF;
    border: thin solid rgb(255 255 255 / 30%);
    padding: 0 10px;
}

.btnLanguageSelection button::after {
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #FFF;
}

.btnLanguageSelection button[aria-expanded="true"]:after {
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #4d4d4d;
}

.container-fluid {
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.HowItWorksTop {
    padding:45px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HowItWorksTop h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000066;
    font-size: 3em;

}

.HowItWorksTop span{
    width: 50%;
    text-align: center;

}

.HowItWorksActive {
    margin-right: 15px;
    color: #cc3366;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    background-color: #cc33661f;
    border: none;
    border-radius: 10px;
    height: 40px;
    width: 30%;
    outline: none;
}

.HowItWorksInactive {
    text-decoration: none;
    background: none;
    border: none;
    margin-right: 15px;
    text-decoration: none;
    color: #666666;
    font-size: 18px;
    outline: none;
}

.HowItWorksInactive:hover {
    color: #cc3366;
    font-weight: bold;
    cursor: pointer;
}


.HowItWorksCenter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.HowItWorksCenter h3 {
    color: #000066;
    margin-bottom: 10px;
}

.HowItWorksCenter p {
    color: #333;
    font-size: 14px;
   
}

.centerElement {
    padding: 25px;
    display: flex;
    flex-direction: column;
    width: 230px;
    margin-right: 25px;
    height: 96%;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 20px #cac9c9;
}

.centerElement h3 {
    text-align: left;
}

.centerElement p {
 text-align: justify;
 letter-spacing: -1px;
 font-size: 15px;
 line-height: 22px;
}

.centerElement img {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
}


.HowItWorksBottom {
    padding: 50px;
    
}

.HowItWorksButton {
    width: 380px;
    height: 43px;
    border-radius: 20px;
    font-size: 20px;
    background-color: #cc0057;
    border: none;
    color: white;
}

.HowItWorksButton:hover {
    cursor: pointer;
    background-color: #cc3366;
    

}

.CSSgal{
    display: none;
}



@media only screen and (max-width:1240px){
    .container-fluid{
        height: 690px;
    }
    

    .HowItWorksCenter{
        flex-wrap: wrap;
        justify-content: center;
        max-width: 70%;
    }
    .centerElement{
        margin-right: 10px;
        margin-bottom: 10px;
        height: 50%;
    }

    .HowItWorksActive {       
        font-size: 15px;       
        width: 70%;       
    }

    .HowItWorksInactive {
       
        font-size: 15px;

    }

}

@media only screen and (max-width:845px){
    .HowItWorksCenter{
        max-width: 90%;
    }

}

@media only screen and (max-width:768px){
    
    .HowItWorksTop{
    margin-top: 45px;
    }

    .HowItWorksCenter{
        margin-left: 25px;
    }

}

@media only screen and (max-width:661px){
   
.HowItWorksCenter{
        display: none;
    }

    .HowItWorksTop{
        text-align: center;
    }

    .container{
        height: 600px;
    }

.centerElement {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 70px 27%;
    height: 280px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 20px #cac9c9;
    
    
    
}

.centerElement h3 {
    text-align: left;
    color: #000066;
    margin-bottom: 10px;
    
    
}

.centerElement p {
 text-align: justify;
 letter-spacing: -1px;
 font-size: 15px;
 line-height: 22px;
 color:#000066;
}

.centerElement img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.CSSgal {
    display: block;
    width: 100%;
	position: relative;
	overflow: hidden;
	height: 100%; /* Or set a fixed height */
}

/* SLIDER */

.CSSgal .slider {
	height: 100%;
	white-space: nowrap;
	font-size: 0;
    transition: 0.8s;
    
}

/* SLIDES */

.CSSgal .slider > * {
	font-size: 1rem;
	display: inline-block;
	white-space: normal;
	vertical-align: top;
	height: 100%;
	width: 100%;
	background: none 50% no-repeat;
    background-size: cover;
    
}

/* PREV/NEXT, CONTAINERS & ANCHORS */

.CSSgal .prevNext {
	position: absolute;
	z-index: 1;
	top: 52%;
	width: 75%;
    height: 0;
    margin-left: 70px;
    
}

.CSSgal .prevNext > div+div {
    visibility: hidden; /* Hide all but first P/N container */
    
}

.CSSgal .prevNext a {
	background: #ecdde9;
	position: absolute;
	width:       55px;
	height:      55px;
	line-height: 55px; /* If you want to place numbers */
	text-align: center;
    opacity: 1;
	transition: 0.3s;
	-webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    
	
}
.CSSgal .prevNext a:hover {
	opacity: 1;
}
.CSSgal .prevNext a+a {
	left: auto;
	right: 0;
}

/* NAVIGATION */

.CSSgal .bullets {
    position: absolute;
	z-index: 2;
    top: 0;
    margin: 0px 85px;
	padding: 10px 0;
    width: 70%;
    
	text-align: center;
}
.CSSgal .bullets > a {
    display: inline-block;
	width:       30px;
	height:      30px;
	line-height: 30px;
	text-decoration: none;
    text-align: center;
	background: #ecdde9;
	transition: 0.3s;
}
.CSSgal .bullets > a+a {
	background: #ecdde9; /* Dim all but first */
}
.CSSgal .bullets > a:hover {
	background: #ecdde9 !important;
}

/* NAVIGATION BUTTONS */
/* ALL: */
.CSSgal >s:target ~ .bullets >* {      background: #ecdde9;}
/* ACTIVE */
#s1:target ~ .bullets >*:nth-child(1) {background: #cc0057;}
#s2:target ~ .bullets >*:nth-child(2) {background: #cc0057;}
#s3:target ~ .bullets >*:nth-child(3) {background: #cc0057;}
#s4:target ~ .bullets >*:nth-child(4) {background: #cc0057;}
/* More slides? Add here more rules */

/* PREV/NEXT CONTAINERS VISIBILITY */
/* ALL: */
.CSSgal >s:target ~ .prevNext >* {      visibility: hidden;}
/* ACTIVE: */
#s1:target ~ .prevNext >*:nth-child(1) {visibility: visible;}
#s2:target ~ .prevNext >*:nth-child(2) {visibility: visible;}
#s3:target ~ .prevNext >*:nth-child(3) {visibility: visible;}
#s4:target ~ .prevNext >*:nth-child(4) {visibility: visible;}
/* More slides? Add here more rules */

/* SLIDER ANIMATION POSITIONS */

#s1:target ~ .slider {transform: translateX(   0%); -webkit-transform: translateX(   0%);}
#s2:target ~ .slider {transform: translateX(-100%); -webkit-transform: translateX(-100%);}
#s3:target ~ .slider {transform: translateX(-200%); -webkit-transform: translateX(-200%);}
#s4:target ~ .slider {transform: translateX(-300%); -webkit-transform: translateX(-300%);}
/* More slides? Add here more rules */

.CSSgal a {
	border-radius: 50%;
	margin: 0 3px;
	color: rgba(0,0,0,0.8);
	text-decoration: none;
}
    
     .HowItWorksBottom button{
        font-size: .9rem;
         width: 242px ;
         height: 35px;
         
         
     } 

}

@media only screen and (max-width:645px){
    .centerElement {
        width: 210px;
        margin: 70px 28.4%;
        height: 450px;
    }

    .centerElement h3{
        font-size: 18px;
    }

    .centerElement p{
        font-size: 14px;
    }

    .centerElement img{
        width: 96px;
        height: 96px;
    }

    .CSSgal .bullets {
        margin: 0px 15%;
        width: 70%;
    }

    .CSSgal .prevNext {
        
        margin-left: 3%;
        margin-right: 5%;
        width: 90%;
        
    }

    .prevNext a{
        background: #F0F8FF;
    }

    .CSSgal .prevNext a{
        background: aliceblue;
        width: 0;
        height: 0;
        border: 30px solid;
        border-radius: 0;
        border-color: transparent #ecdde9 transparent transparent
        }
        .CSSgal .prevNext a+a{
            width: 0;
            height: 0;
            border: 30px solid;
            border-radius: 0;
            border-color: transparent transparent transparent #ecdde9;
            }
     
}

@media only screen and (max-width:600px){
    .CSSgal .bullets {
        margin: 0px 15%;
        width: 70%;
    }

    .CSSgal .prevNext {
        
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
        
    }
}

@media only screen and (max-width:556px){
    .centerElement{
        margin: 70px 24%;
    }
}

@media only screen and (max-width:445px){
    .centerElement {
        margin: 70px 25%;
    }

    .centerElement br{
        display: none;
    }

    .HowItWorksTop span{
        width: 350px ;
        margin-left: 20px;
    }

    .CSSgal .bullets {
        margin: 0px 16%;
        width: 70%;
    }

    .CSSgal .prevNext {
        
        margin-left: 1%;
        margin-right: 1%;
        width: 100%;
        
    }

    .prevNext a{
        background: #F0F8FF;
    }

    .CSSgal .prevNext a{
        background: aliceblue;
        width: 0;
        height: 0;
        border: 30px solid;
        border-radius: 0;
        border-color: transparent #ecdde9 transparent transparent
        }
        .CSSgal .prevNext a+a{
            width: 0;
            height: 0;
            border: 30px solid;
            border-radius: 0;
            border-color: transparent transparent transparent #ecdde9;
            }

     
}

@media only screen and (max-width:425px){
    .centerElement {
        width: 200px;
        margin: 70px 23%;
        padding: 25px 20px;
    }

    .centerElement h3{
        font-size: 16px ;
    }

    .centerElement p{
        font-size: 12px;
        text-indent: 0px;
        letter-spacing: 0px;
        
    }

    .centerElement img{
        width: 90px;
        height: 90px;
        margin-bottom: 0px;
    }

    .CSSgal .bullets {

        margin: 0px 17%;
        
    }

    .CSSgal .prevNext {
        
        margin-left: 1%;
        margin-right: 1%;
        width: 100%;
        
    }

    .prevNext a{
        background: #F0F8FF;
    }

    .CSSgal .prevNext a{
        background: aliceblue;
        width: 0;
        height: 0;
        border: 30px solid;
        border-radius: 0;
        border-color: transparent #ecdde9 transparent transparent
        }
        .CSSgal .prevNext a+a{
            width: 0;
            height: 0;
            border: 30px solid;
            border-radius: 0;
            border-color: transparent transparent transparent #ecdde9;
            }
}

@media only screen and (max-width:375px){
   .container{
       height: 500px;
   }
   
    .centerElement {
        margin: 70px 19.5%;
        width: 200px;
        height: 450px;
        padding: 25px 15px;
    }

    .centerElement p{
        font-size: 13px;
        text-indent: 0px;
        letter-spacing: -1px;
        margin-bottom: 0px;
        
    }

    .centerElement h3{
        font-size: 17px;
    }

    .centerElement img{
        width: 90px;
        height: 90px;

    }

    .CSSgal .prevNext {
        
        margin-left: 0.1%;
        margin-right: 0%;
        width: 100%;
        
    }

    .prevNext a{
        background: #F0F8FF;
    }

    .CSSgal .prevNext a{
        background: aliceblue;
        width: 0;
        height: 0;
        border: 30px solid;
        border-radius: 0;
        border-color: transparent #ecdde9 transparent transparent
        }
        .CSSgal .prevNext a+a{
            width: 0;
            height: 0;
            border: 30px solid;
            border-radius: 0;
            border-color: transparent transparent transparent #ecdde9;
            }
    .CSSgal .bullets {

        margin: 0px 16%;
        
    }

    .CSSgal .bullets > a {
        width:       20px;
        height:      20px;
        line-height: 20px;
        margin-bottom: -10px;
    }

    .HowItWorksTop span{
        width: 350px ;
        margin-left: 20px;
    }

    .HowItWorksBottom{
        padding: 20px 50px;
    }

    


      
}

@media only screen and (max-width:320px){
    .container{
       height: 520px; 
    }
    .centerElement {
        margin: 70px 15%;
        width: 195px;
        height: 450px;
        box-shadow: 0 0 16px #cac9c9;
        
    }


    .centerElement img{
        margin-bottom: 0px;
    }

    .HowItWorksTop{
        padding: 45px 20px  25px 20px;
    }
    .HowItWorksTop span{
        width: 300px;
        margin-left: 20px;

    }

    .HowItWorksInactive{
        font-size: 16px;
        width: 135px ;
    }
    
    .HowItWorksActive{
        font-size: 16px;
        width: 135px ;
         
    }

    .CSSgal .bullets {
        margin: 0px 15%;
        
    }
    .CSSgal .prevNext {
        background: aliceblue;
        margin-left: -0%;
        margin-right: 0%;
        width: 100%;
        
        
    }
    .prevNext a{
        background: aliceblue;
       
    }

    .CSSgal .prevNext a{
        background: aliceblue;
        width: 0;
        height: 0;
        border: 18px solid;
        border-radius: 0;
        border-color: transparent #ecdde9 transparent transparent;
         
        }
        .CSSgal .prevNext a+a{
            background: aliceblue;
            width: 0;
            height: 0;
            border: 18px solid;
            border-radius: 0;
            border-color: transparent transparent transparent #ecdde9;
            
        }

        .HowItWorksBottom{
            padding: 20px;
            
        }
}



.gmcContainer {
    /* height: 360px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 45px 0px;
}

.titulo{
    color: #cc0057;
    margin-bottom: 20px;
    font-size: 3em;
}

.gmcLeft {
    padding: 30px;
    width: 50%;
}

.gmcLeft h1 {
    color: #666;
    margin-bottom: 15px;
    
}
.gmcLeft h3 {
    font-size: 20px;
    margin-bottom: 18px;
    color: #000066;
    
}
.gmcLeft p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
}

.gmcLeft li{
    font-size: 14px;
}
.gmcLeft i{
    font-size: 18px;
    color: green;
}

.gmcButton {
    width: 200px;
    height: 35px;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    background-color: #cc0057;
    border: none;
    color: white;
}

.gmcButton:hover {
    cursor: pointer;
    background-color: #cc3366;
}

.gmcRight {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    margin-top: 2%;
}

.gmcRight span {
    margin-bottom: 15px;
    color: #000066;
}



@media only screen and (max-width: 1024px){
    .gmcContainer {
        height: 400px;
        width: 100%;
        align-items: center;
       
    }
} 

@media only screen and (max-width: 884px){
    .gmcLeft{
        width: 500px;
    }
    .gmcLeft br{
        display: none;
    }
} 

@media only screen and (max-width: 768px){
    
    .gmcContainer {
        height: 550px;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px 0px;
       
    }


    .gmcLeft{
        padding: 5px 20px;
        max-width: 90%;
    }


    .gmcLeft h1 {
        text-align: center;
        font-size: 0.8rem;
        
        
    }

    .gmcLeft p {
        text-align: center;
        font-size: 0.8em;

        
    }

    .gmcLeft br{
        display: none;
    }

    .gmcRight{
        padding: 30px 0px ;
    }

    .gmcRight span{
        display: none;
    }

    .gmcButton{
        display: none;
    }

    .gmcMobileButton {
        width: 200px;
        height: 35px;
        border-radius: 20px;
        font-size: 15px;
        font-weight: bold;
        background-color: #cc0057;
        border: none;
        color: white;
    }
    
    .gmcMobileButton:hover {
        cursor: pointer;
        background-color: #cc3366;
    }
} 

@media only screen and (max-width: 600px){
   .gmcContainer{
       width: 100%;
        margin: 120px 0px;
       
   }

   .gmcRight{
       width: 100%;
   }

   .gmcLeft h1{
       font-size: 0.8rem;
   }
}

@media only screen and (max-width: 375px){
    .gmcContainer{
        height: 550px;
    }
}

@media only screen and (max-width: 320px){
    
    .gmcLeft {
        padding:5px 10px ;
    }
 }

@media only screen and (min-width: 769px){
        .gmcMobileButton{
        display: none;
    }
}

.SendOrRecieveContainer {
    background-image: url(/static/media/voce_pode_ser_global.45dfc2a2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.SendOrRecieveContentLeft {
    /* height: 300px; */
    /* border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.5); */
    padding: 40px 30px 40px 30px;
    
    
    
}

.SendOrRecieveContentRight {
    width: 600px;
}

.SendOrRecieveContentLeft h1 {
    color: #000066;
}
.SendOrRecieveContentLeft p {
    font-size: 14px;
    color: #000066;
    margin-bottom: 30px;
}

.textVideo{
    font-size: 48px;
    color: #fff;
    margin-bottom: 16px;
}

.modal__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 28, 28, 0.19);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-name: modal-video;
            animation-name: modal-video;
    transition: opacity 0.3s ease-out;
    z-index: 100;
  }
  
  .modal__align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .modal__content {
    width: 800px;
    height: 500px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
      0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
      0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
      0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    background: transparent;
    color: #000;
    margin: 0rem 4rem;
  }

  .modal__close {
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    bottom: 50px;
    width: 32px;
    height: 32px;
    padding: 0;
  }
  
  .modal__video-align {
    display: flex;
    position: relative;
    bottom: 37px;
  }
  
  .modal__video-style {
    border-radius: 20px;
    z-index: 100;
  }
  
  .modal__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .modal__spinner {
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    font-size: 40px;
    color: #1b6aae;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @media screen and (max-width: 800px) {
    .modal__content {
      margin: 0rem 1rem;
      width: 100%;
    }
    .modal__video-style {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 499px) {
    .modal__content {
      background: transparent;
      height: auto;
    }
    .modal__video-align {
      bottom: 0px;
    }
    .modal__video-style {
      height: auto;
    }
  }

.HowSend {
    width: 200px;
    height: 35px;
    border-radius: 20px;
    /* font-weight: bold; */
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;
    float: center;
}

.HowSend:hover {
    cursor: pointer;
    background-color: #cc3366;
}

.buttonDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
}

@media only screen and (max-width: 998px){
    .SendOrRecieveContentLeft{
        margin: 10px;
        width:300px ;
        min-width: 250px;
    }

    .SendOrRecieveContentLeft br{
        display: none;
    }

    .SendOrRecieveContentLeft h1{
        font-size: 1.8rem ;
        text-align: center;
        padding-bottom: 5px;
        
    }
    

}
@media only screen and (max-width: 768px){
    .SendOrRecieveContainer{
        height: 800px;
        
    }
    .SendOrRecieveContentRight{
        display: none;
        
    }

    .SendOrRecieveContentLeft{
        margin: 50px 10px;
        width:250px ;
        align-self: center;
    }

    .HowSend {
        width: 180px;
        height: 30px;
        font-weight: normal;
        font-size: 14px;
       
    }
  

}

@media only screen and (max-width:320px){
    .SendOrRecieveContentLeft{
        margin: 50px 0px;
    }
}

.container-flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #F2F2F2;
    padding-bottom: 100px;
}

.topCoin{
    width: 1000px;
}

.topCoin p{
    text-align: center;
    max-width: 700px;
    margin: auto;
    padding-bottom: 13px;
    color: #333;
    line-height: 22px;

}

.topCoin h2{
    margin: 80px 200px 30px 200px;
    text-align: center;
    color: #000066;
    font-size: 2.5em;
}

.bottomCoin{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.stableC {
    align-self: center;
    text-align: center;
    margin-left: 60px;
    height: 150px;
    color: #333;
}

.stableC h4{
    padding-bottom: 10px;
    color: #333;
}

.stableC p{
    text-align: center;
    margin-bottom: 2px;
}

.transC {
    text-align: center;
    margin-left: 220px;
    height: 150px;
    color: #333;
}

.transC h4{
    padding-bottom: 10px;
    color: #333;
}

.transC p{
    text-align: center;
    margin-bottom: 2px;
}
.safC {
    text-align: center;
    margin-left: 220px;
    height: 150px;
    color: #333;
}

.safC h4{
    padding-bottom: 10px;
    color: #333;
}

.safC p{
    text-align: center;
    margin-bottom: 3px;
}
#Balanca, #Lupa, #handShake{
    width:80px;
    height: 80px;
    margin: 10px auto;
}

.gmcTaxes {
    width: 40%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    border-radius: 10px;
    padding: 20px;
    background-color: #cc0057;
    margin: auto;

}
.gmcTaxes div {
    color: white;
}

#gmcBottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.gmcTaxes div p {
    
    float: right;
    font-size: 12px;
    color: white;
    text-align: center;

}
.gmcTaxes hr {
    border-width: 0;
    height: 1px;
    background-color: white;
    color: white;
}

@media only screen and (max-width:818px){

}

@media only screen and (max-width:768px){
    .container-flex{
        display: flex;
        flex-direction: inherit;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1000px ;
        padding-bottom: 60px;
        
    }

    .topCoin{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin-top: 30px;
        
    }

    .topCoin br{
        display: none;
    }

    .topCoin h2{
        width: 90%;
        margin: 20px 20px;     
        
    }

    .topCoin p {
        padding: 0px 0px 40px;
    }

    .bottomCoin{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        
    }

    .stableC,
    .transC,
    .safC{
        margin: 30px 0;
    }
}

@media only screen and (max-width:425px){

    .container-flex{
        display: flex;
        flex-direction: inherit;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1173px ;
        padding-bottom: 60px;
        
    }

    .topCoin{
        margin-top: 10px;
    }
    .topCoin h2{
        font-size: 2.2rem;
    }

    .topCoin p{
        font-size: 0.9rem;
        padding: 0px 0px 30px;
    }
}

@media only screen and (min-width:1370px){

.topCoin{
margin-right: 50px;
margin-left: 50px;
}

.stableC{
text-align: center;
margin-left: 150px;
height: 150px;
}

.transC{
text-align: center;
margin-left: 200px;
height: 150px;
}

.safC{
text-align: center;
margin-left: 200px;
margin-right: 100px;
height: 150px;
}

}
.DownloadAppContainer {
    padding: 100px 100px 0px 100px;;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.DownloadAppContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cc0057;
    border-radius: 20px;
    padding: 20px;
}

.DownloadAppContent h1 {
    color: white;
    margin-bottom: 15px;
}

.DownloadAppContent p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
}

.DownloadAppStores {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    
    
}

.DownloadAppAppStore {
    width: 200px;
    border-radius: 10px;
}
.DownloadAppGooglePlay {
    width: 188px;
    border-radius: 10px;
}

.DownloadAppSpace {
    width: 20px;
    
}

@media only screen and (max-width: 768px){
   .DownloadAppContainer{
       padding: 50px 100px;
   }
    .DownloadAppAppStore{
       width: 150px;
   }

   .DownloadAppGooglePlay{
       width: 147px;
   }
   
   .DownloadAppContent{
       max-width: 90%;
   }

}
@media only screen and (max-width:600px){
    .DownloadAppContainer{
        padding: 100px 0px;
    }

    .DownloadAppContent{
        width: 80%;
    }
    .DownloadAppContent p{
        font-size: .9rem;
    }

    .DownloadAppStores img{
        max-width: 100%;
        margin-right: 5px;
        margin-left: 5px;
    }
}


@media only screen and (max-width:520px){
    .DownloadAppContainer{
        padding: 50px 20px;
    }
}

@media only screen and (max-width:375px){
    .DownloadAppContainer{
        padding: 50px 10px;
    }

    .DownloadAppContent{
        width: 80%;
    }
    .DownloadAppContent p{
        font-size: .9rem;
    }

    .DownloadAppStores img{
        max-width: 100%;
        margin-right: 5px;
        margin-left: 5px;
    }
}

@media only screen and (max-width:320px){
    .DownloadAppContent{
        width: 85%;
    }
    .DownloadAppStores img{
        width: 55%;
    }

    .DownloadAppContent p{
        font-size: .8rem;
    }
}
.AskedQuestionsContainer {
    display: flex;
    justify-content: center;
}

.AskedQuestionsLeft {
    margin-right: 50px;
    padding: 40px;
}

.AskedQuestionsLeft h1 {
    margin-bottom: 15px;
    color: #000066;
    font-size: 3em;
}

.AskedQuestionsLeft p {
    font-size: 14px;
    line-height: 22px;
    color: #000066;
}

.AskedQuestionsRight {
    width: 600px;
    padding: 40px;
    color: #666666;
}

.AskedQuestionsRight p {
    margin: 10px;
}

.buttonAskedQuestions {
    margin-top: 20px;
    width: 150px;
    height: 30px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #cc0057;
    border: none;
    color: white;
}

.buttonAskedQuestions:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){
    .AskedQuestionsContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
        height: auto; 
        width: 100%;
    }

    .AskedQuestionsLeft{
        margin: 0;
    }

    .AskedQuestionsLeft h1,
    .AskedQuestionsLeft p{
        text-align: center;
    }

    .AskedQuestionsRight{
        padding: 40px 0px;
        width: 100%;
    }

    .buttonAskedQuestions{
        margin-left: 40%;
        margin-top: 40px;
        margin-bottom: 100px;
    }
}

@media only screen and (max-width:500px){
    .buttonAskedQuestions{
        margin-left: 34%;
    }
}

@media only screen and (max-width:425px){
    .buttonAskedQuestions{
        margin-left: 33%;
        margin-bottom: 10px;
    }

    .AskedQuestionsContainer{
        height: 1000px;      
    }

    .AskedQuestionsRight{
        margin-bottom: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 10px;
    }

    
}

@media only screen and (max-width:375px){
    .buttonAskedQuestions{
        margin-left: 30%;
    }

    .fa-ul {
        width: 190px;
        margin-left: 0.5em !important;
    }

    .fa-ul > li {
        margin-left: 17px;
    }
}
    



.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

p {
    text-align: justify;
    line-height: 20px;
    margin-bottom: 10px;
    
}

.conteudo{
    background-color:#fff;
}


 
.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    -webkit-animation: fadein 0.35s ease-in;
            animation: fadein 0.35s ease-in;
}
.accordion__panel i{
    font-size: 18px;
    color: green;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width:768px){
   .accordion{
       width: 80%;
       margin: auto;
   }
   
    .accordion__item{
       width: 93%;
    }

}

@media only screen and (max-width:425px){
    .AskedQuestionsContainer{
        height: auto;
    }
    .AskedQuestionsRight{
        width: 95%;
        margin-bottom: 100px;
    }

    .AskedQuestionsRight p{
        text-indent: inherit;
        letter-spacing: -1px;
        text-align: start;
    }

    

}
.startNowContainer {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000066;
}
.startNowTop {
    height: 250px;
    display: flex;
    justify-content: center;
}

.startNowImg{
    position: relative;
    top: -155px;
    width: 450px;
    height: 370px;
}

.startNowRight {
    padding: 45px 0 0 50px;
    color: white;
    width: 500px;
}

.startNowRight p {
    font-size: 14px;
    margin: 15px 0 15px 0;
}

.startNowButton {
    width: 130px;
    height: 25px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #cc0057;
    border: none;
    color: white;
}

.startNowButton:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){
    
    .startNowTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 550px;
    }

    .startNowImg{
        position: relative;
        top: -280px;
       
        width:70%;
        height: 470px;
    }

    .startNowRight{
        margin-top: -220px;
        padding: 0;
        width: 90%;
    }

    .startNowRight h1,
    .startNowRight p{
        text-align: center;
    }

    .startNowButton{
        margin-left: 40%;
        margin-top: 20px;
    }
}
@media only screen and (max-width:700px){
    .startNowImg{
        width: 90%;
    }
}
@media only screen and (max-width:600px){
    .startNowImg{
        width: 100%;
        height: 440px ;
    }
}
@media only screen and (max-width:425px){
    .startNowButton{
        margin-left: 30%;
       
    }

    .startNowTop{
        width: 100%;
    }

    .startNowImg{
        width: 405px;
        height: 350px;
        top: -260px;
        left: 1%;
        
    }

     
}

@media only screen and (max-width:375px){
    .startNowImg{
        width: 370px;
        height: 320px;
        top: -280px;
        left: 0;
    
    }
}

@media only screen and (max-width:320px){
    .startNowImg{
        width: 320px;
        height: 280px;
    
    }
}


.separatorContainer {
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #000066;
}
.separatorHr {
    text-align: center;
}

.separatorHr hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.658);
}
.footerContainer {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000066;
}

.footerCenter {
    display: flex;
    justify-content: space-between;
    min-height: 220px;
    padding: 100px;
}

.footerAdderss {
    padding: 10px;
    color: white;
    font-size: 12px;
}
.footerAdderss a{   
    color: white;
    text-decoration: none;    
}

.footerAdderss img {
    margin-bottom: 10px;
}

.footerAdderss p{
line-height: 25px;
}

.footerNavigation {
    padding: 10px;
    color: white;
    width: 160px;
}

.footerNavigation h3 {
    margin-bottom: 20px;
}

.footerNavigation a {
    color: white;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 12px;
    line-height: 25px;
}
.footerNavigation a:hover {
    font-weight: bold;
}

.footerLegal {
    padding: 10px;
    color: white;
    width: 170px;
}

.footerLegal h3 {
    margin-bottom: 20px;
}

.footerLegal a {
    color: white;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 12px;
    line-height: 25px;
    
}

.footerLegal a:hover {
    font-weight: bold;
}

.footerSocialMedia {
    padding: 10px;
    color: white;
}

.footerSocialMedia h3 {
    margin-bottom: 20px;
}
.footerSocialMedia img {
    margin: 5px;
}

.footerBottom {
    padding: 15px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.footerBottom p {
    margin: 5px;
    font-size: 12px;
    color: #000066;
    text-align: center;
}

@media only screen and (max-width:768px){
    .footerCenter{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 760px;
        padding: 50px 100px 100px 100px;
    }

    .footerAdderss,
    .footerAdderss p{
        text-align: center;
    }

    .footerNavigation,
    .footerLegal,
    .footerSocialMedia{
        text-align: center;
    }
}

@media only screen and (max-width:645px){
    .footerCenter{
        height: 800px;
    }
}

@media only screen and (max-width:425px){
    .footerCenter{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 890px;
        padding: 50px 100px 100px 100px;
    }
}

@media only screen and (max-width:320px){
    .footerSocialMedia{
        padding: 10px 0;
    }
}
.HowTextWorksTop {
    padding:45px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.internationTransferContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
    margin-top: -4%;
}

.internationTransferLeft {
    padding: 30px;
}

.internationTransferImg {
    width: 500px;
}

.internationTransferRight {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.internationTransferRight h1 {
    color: #000066;
    margin-bottom: 20px;
}

h2 {
    font-size: 26px;
}

.internationTransferRight p {
    color: #666;
    margin-bottom: 20px;
    line-height: 22px;
}
.internationTransferButton {
    width: 200px;
    height: 35px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;
    
}

.internationTransferButton:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){
    .internationTransferContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 920px;
        width: 100%;
        
    }

    .internationTransferLeft{
        padding: 30px 0;
        width: 100%;
    }

    .section5Img{
        width: 80%;
        padding-left: 10%;
    }

    .internationTransferRight{
        padding: 50px 100px;
    }


    .internationTransferRight br{
        display: none;
    }

    .internationTransferRight h1,
    .internationTransferRight p{
        text-align: center;
    }

    .internationTransferRight h2{
        text-align: center;
        font-size: 26px;
    }

    .internationTransferButton{
        align-self: center;
        margin-top: 30px;
    }
    .internationTransferRight a{
        text-align: center;
    }
}

@media only screen and (max-width:645px){
    .internationTransferContainer{      
        height: 500px;
    }

    .internationTransferRight {
        padding: 0px 100px;
    }
 }

@media only screen and (max-width:520px){
    .internationTransferContainer{
        height: 750px;
    }
    .section5Img{
        width: 350px; 
        padding-left: 0;
    }

    .internationTransferRight h1{
        font-size: 1.9rem;
    }

    .internationTransferRight{
        padding: 50px 30px;
    }

    .internationTransferButton{
        margin-right: 0;
    }
}

@media only screen and (max-width:375px){
    .internationTransferContainer{
        height: 750px;
        margin-top: 40px;
    }
    .internationTransferRight{
        padding: 20px;
    }

    

    .internationTransferLeft{
        width: 100%;
    }

}

@media only screen and (max-width:320px){
    .section5Img{
        width: 100%;
    }

    .internationTransferRight{
        padding: 30px 15px;
    }
    .internationTransferButton{
        width: 180px;
    }
}



.stableCoinContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
    margin-top: -4%;
}

.stableCoinRight {
    padding: 30px;
}

.stableCoinImg {
    width: 450px;
}

.stableCoinLeft {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stableCoinLeft h1 {
    color: #000066;
    margin-bottom: 20px;
}

.stableCoinLeft p {
    color: #666;
    margin-bottom: 20px;
    list-style: 22px;
}
.stableCoinButton {
    width: 200px;
    height: 35px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;
    
}

.stableCoinButton:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){
    .stableCoinContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 850px;
        width: 100%;
        
    }

    .stableCoinLeft{
        padding: 30px 100px;
    }

    .stableCoinLeft br{
        display: none;
    }

    .stableCoinLeft h1,
    .stableCoinLeft p{
        text-align: center;
    }

    .stableCoinRight{
        padding: 30px 0px;
    }

    .stableCoinButton{
        align-self: center;
        margin-top: 30px;
    }
    .stableCoinLeft a{
        text-align: center;
    }
}

@media only screen and (max-width:520px){
    .stableCoinContainer{
        height: 750px;
    }
    .stableCoinImg{
        width: 300px;
    }

    .stableCoinLeft h1{
        font-size: 1.9rem;
    }

    .stableCoinLeft{
        padding: 30px;
    }

    .stableCoinButton{
        margin-right: 0;
    }
    
}

@media only screen and (max-width:375px){
    .stableCoinContainer{
        height: 700px;
    }

    .stableCoinLeft{
        padding: 20px 20px;
    }

    
}


@media only screen and (max-width:320px){
    .stableCoinImg{
        width: 100%;
    }

    .stableCoinLeft{
        padding: 30px 15px;
    }

    .stableCoinRight{
        padding: 30px 10px;
    }
    .stableCoinButton{
        width: 180px;
    }
}

.knowOurHistoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
    margin-top: -4%;
}

.knowOurHistoryLeft {
    padding: 30px;
}

.knowOurHistoryImg {
    width: 500px;
}

.knowOurHistoryRight {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.knowOurHistoryRight h1 {
    color: #000066;
    margin-bottom: 20px;
}

.knowOurHistoryRight p {
    color: #666;
    margin-bottom: 20px;
    line-height: 22px;
}
.knowOurHistoryButton {
    width: 220px;
    height: 35px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;
    
}

.knowOurHistoryButton:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){
    .knowOurHistoryContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 850px;
        width: 100%;
        
    }

    .knowOurHistoryLeft{
        padding: 30px 0px;
    }

    .knowOurHistoryRight{
        padding: 30px 100px;
    }
   

    .knowOurHistoryRight  br{
        display: none;
    }

    .knowOurHistoryRight h1,
    .knowOurHistoryRight p{
        text-align: center;
    }

   .knowOurHistoryRight a{
       align-self: center;
       padding: 20px 0;
   }

   .knowOurHistoryImg{
       width: 100%;
   }


}

@media only screen and (max-width:520px){
    .knowOurHistoryContainer{
        height: 750px;
    }
    .knowOurHistoryImg{
        width: 350px;
    }

    .knowOurHistoryRight h1{
        font-size: 1.9rem;
    }

    .knowOurHistoryRight{
        padding: 30px;
    }

    .knowOurHistoryRight a{
        margin-right: 0;
    }

}

@media only screen and (max-width:375px){
    .knowOurHistoryButton{
        margin: 0;
    }

    .knowOurHistoryRight{
        padding: 20px;
    }
}

@media only screen and (max-width:320px){
    .knowOurHistoryImg{
        width: 100%;
    }

    .knowOurHistoryLeft{
        padding: 30px 0;
    }

    .knowOurHistoryRight{
        padding: 30px 15px;
    }
    .internationTransferButton{
        width: 180px;
    }
}

.policyContainer h1{
    color: #cc0057;
    padding-bottom: 5px;   
}

.policyContainer h4{
    color: #cc0057;
    font-size: 0.8em;
    padding-bottom: 20px;
}

/*.policyContainer h2{
    color: #000066;
    padding-top: 20px;
    padding-bottom: 10px;    
}*/


.policyContainer p{
    padding-bottom: 10px;
    text-align: justify;
}

#politica1{
 padding-top: 80px;
 padding-bottom: 50px;
 margin-left: 100px;
 margin-right: 100px;
 }

 @media screen and (max-width:700px){
     #politica1{
         margin-left: 50px;
         margin-right: 50px;
     }
 }

 @media only screen and (max-width:425px){
    #politica1{
        margin-left: 20px;
        margin-right: 20px;
    }
    .policyContainer p{
         font-size: 14px ;
     }
     .policyContainer h1,
     .policyContainer h4{
         text-align: center;
     }
 }






.termsContainer h1{
    color: #cc0057;
    padding-bottom: 5px;   
}

.termsContainer h4{
    color: #cc0057;
    font-size: 0.8em;
    padding-bottom: 20px;
}

.termsContainer p{
    padding-bottom: 10px;
    text-align: justify;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid-main-text {
    text-decoration: underline;
}

#terms {
    padding-top: 80px;
    padding-bottom: 50px;
    margin-left: 100px;
    margin-right: 100px;
}

 @media screen and (max-width:700px){
    #terms {
        margin-left: 50px;
        margin-right: 50px;
    }
 }

 @media only screen and (max-width:425px){
    #terms {
        margin-left: 20px;
        margin-right: 20px;
    }

    .termsContainer p {
        font-size: 14px;
    }

    .termsContainer h1,
    .termsContainer h4 {
        text-align: center;
     }
 }






.flex-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    

}

.item1 {
    max-width: 900px;
    text-align: center;


    
}

.item1 h1{
    color: #cc0057;  
    margin-bottom: 20px; 
    font-size: 3em; 
}


.item2 p{
    max-width: 650px;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    line-height: 22px;
}
.item3 {
    max-width: 650px;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    line-height: 22px;
}


#btn {
    background: #cc0057;
    color: #fff; 
    font-size: 14px;   
    border-radius: 30px;
    border: 5px solid transparent;
    padding-right: 5px;
    padding-left: 5px;
    height: 30px;
}

#btn:hover {
    cursor: pointer;
    background-color: #cc3366;
    color: white;
}


.modal__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 28, 28, 0.19);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-name: modal-video;
            animation-name: modal-video;
    transition: opacity 0.3s ease-out;
    z-index: 100;
  }
  
  .modal__align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .modal__content {
    width: 800px;
    height: 500px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
      0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
      0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
      0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    background: transparent;
    color: #000;
    margin: 0rem 4rem;
  }

  .modal__close {
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    bottom: 50px;
    width: 32px;
    height: 32px;
    padding: 0;
  }
  
  .modal__video-align {
    display: flex;
    position: relative;
    bottom: 37px;
  }
  
  .modal__video-style {
    border-radius: 20px;
    z-index: 100;
  }
  
  .modal__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .modal__spinner {
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    font-size: 40px;
    color: #1b6aae;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @media screen and (max-width: 800px) {
    .modal__content {
      margin: 0rem 1rem;
      width: 100%;
    }
    .modal__video-style {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 499px) {
    .modal__content {
      background: transparent;
      height: auto;
    }
    .modal__video-align {
      bottom: 0px;
    }
    .modal__video-style {
      height: auto;
    }
  }

.HowSend {
    width: 200px;
    height: 35px;
    border-radius: 20px;
    /* font-weight: bold; */
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;
    float: center;
}

.HowSend:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){  
       
    .item1{
        padding: 35px 20px;
    }
    .item1 br,
    .item2 br{
        display: none;
    }

    .item1 h1{
        font-size: 2.6rem;
    }

    .item2{
        width: 90%;
    }
}

@media only screen and (max-width:425px){
   
    .item1{
        padding: 35px 10px;
    }

    .item1 h1{
        font-size: 2.1rem;
    }

    .item2 p{
        font-size: .9rem;
        padding: 0 10px;
    }
    
}


.howTransferContainer {
    padding: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.howTransferContent {
    padding: 45px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.howTransferContent h1 {
    font-size: 50px;
    margin-bottom: 20px;
    color: #cc0057;
}
.howTransferContent p {
    margin-bottom: 20px;
    color: #333333;
    text-align: center;
    line-height: 22px;
}

.starttoSendButton {
    width: 220px;
    height: 35px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;
}

.starttoSendButton:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){
   
    .howTransferContent{
        padding: 45px 20px;
    }
    .howTransferContent br{
        display: none;
    }

    .howTransferContent h1{
        font-size: 2.5rem;
    }
}

@media only screen and (max-width:425px){
    .howTransferContainer{
        padding: 45px 0px;
    }

    .howTransferContent{
        padding: 45px 10px;
    }

    .howTransferContent h1{
        font-size: 2.1rem;
    }

    .howTransferContent p{
        font-size: .9rem;
        padding: 0 10px;
    }
    
    .starttoSendButton{
        width: 190px;
    }

    
}
.StartIsEasyContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.StartIsEasyLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.StartIsEasyLeft h1 {
    margin-bottom: 20px;
    color: #000066;
    
}

.StartIsEasyLeft h2 {
    margin-bottom: 10px;
    color: #000066;
}

.StartIsEasyLeft p {
    color: #333;
    line-height: 22px;
}

.StartIsEasyRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.StartIsEasyRight img {
    width: 360px;
}

@media only screen and (max-width:768px){
    .StartIsEasyContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 800px;
        width: 100%;
        
    }

    .StartIsEasyLeft{
        width: 90%;
    }

    .StartIsEasyLeft p br{
        display: none;
    }

    .StartIsEasyLeft h1,
    .StartIsEasyLeft h2{
        text-align: center;
    }

    .StartIsEasyLeft h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .StartIsEasyLeft{
        width: 90%;
        padding: 40px 0;
    }
    .StartIsEasyRight img{
        width: 350px;     
    }

    .StartIsEasyRight{
        width: 100%;
        padding: 20px 0;
    }
}

@media only screen and (max-width:375px){
    .StartIsEasyRight img{
        width: 300px;     
    }

}
.InsertFundsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.InsertFundsRight {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.InsertFundsRight h1 {
    margin-bottom: 20px;
    color: #000066;
    
}

.InsertFundsRight h2 {
    margin-bottom: 10px;
    color: #000066;
}

.InsertFundsRight p {
    color: #333;
    line-height: 22px;
}

.InsertFundsLeft {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.InsertFundsLeft img {
    width: 360px;
}

@media only screen and (max-width:768px){
    .InsertFundsContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .InsertFundsRight{
        width: 90%;
    }

    .InsertFundsRight p br{
        display: none;
    }

    .InsertFundsRight h2{
        text-align: center;
    }

    .InsertFundsRight h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .InsertFundsRight{
        width: 90%;
        padding: 40px 0;
    }
    .InsertFundsLeft img{
        width: 350px;     
    }

    .InsertFundsLeft{
        width: 100%;
        padding: 20px 0;
    }
}

@media only screen and (max-width:375px){
    .InsertFundsLeft img{
        width: 300px;     
    }

}
.ConvertFundsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.ConvertFundsLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ConvertFundsLeft h2 {
    margin-bottom: 10px;
    color: #000066;
}

.ConvertFundsLeft p {
    color: #333;
    line-height: 22px;
}

.ConvertFundsRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.ConvertFundsRight img {
    width: 400px;
}

@media only screen and (max-width:768px){
    .ConvertFundsContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .ConvertFundsLeft{
        width: 90%;
    }

    .ConvertFundsLeft p br{
        display: none;
    }

    .ConvertFundsLeft h2{
        text-align: center;
    }

    .ConvertFundsLeft h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .ConvertFundsLeft{
        width: 90%;
        padding: 40px 0;
    }
    .ConvertFundsRight img{
        width: 350px;     
    }
    .ConvertFundsRight{
        width: 100%;
        padding: 20px 0;
    }
}

@media only screen and (max-width:375px){
    .ConvertFundsRight img{
        width: 300px;     
    }

}
.DestinationDataContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.DestinationDataRight {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DestinationDataRight h1 {
    margin-bottom: 20px;
    color: #000066;
    
}

.DestinationDataRight h2 {
    margin-bottom: 10px;
    color: #000066;
}

.DestinationDataRight p {
    color: #333;
    line-height: 22px;
}

.DestinationDataLeft {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.DestinationDataLeft img {
    width: 450px;
}

@media only screen and (max-width:768px){
    .DestinationDataContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .DestinationDataRight{
        width: 90%;
    }

    .DestinationDataRight p br{
        display: none;
    }

    .DestinationDataRight h2{
        text-align: center;
    }

    .DestinationDataRight h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .DestinationDataRight{
        width: 90%;
        padding: 40px 0;
    }

    .DestinationDataLeft{
        width: 100%;
        padding: 20px 0;
    }
    .DestinationDataLeft img{
        width: 350px;     
    }
}

@media only screen and (max-width:375px){
    .DestinationDataLeft img{
        width: 300px;     
    }

}
.TransferForWhoYouWantContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.TransferForWhoYouWantLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TransferForWhoYouWantLeft h2 {
    margin-bottom: 10px;
    color: #000066;
}

.TransferForWhoYouWantLeft p {
    color: #333;
    line-height: 22px;
}

.TransferForWhoYouWantRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.TransferForWhoYouWantRight img {
    width: 400px;
}

@media only screen and (max-width:768px){
    .TransferForWhoYouWantContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .TransferForWhoYouWantLeft{
        width: 90%;
    }

    .TransferForWhoYouWantLeft p br{
        display: none;
    }

    .TransferForWhoYouWantLeft h2{
        text-align: center;
    }

    .TransferForWhoYouWantLeft h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .TransferForWhoYouWantLeft{
        width: 90%;
        padding: 40px 0;
    }
    .TransferForWhoYouWantRight{
        width: 100%;
        padding: 20px 0;
    }
    .TransferForWhoYouWantRight img{
        width: 350px;     
    }
}

@media only screen and (max-width:375px){
    .TransferForWhoYouWantRight img{
        width: 300px;     
    }

}
.HowToReceiveContainer {
    padding: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HowToReceiveContent {
    padding: 45px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.HowToReceiveContent h1 {
    font-size: 50px;
    margin-bottom: 20px;
    color: #cc0057;
}
.HowToReceiveContent p {
    margin-bottom: 20px;
    color: #333333;
    text-align: center;
    line-height: 22px;
}

.HowToReceiveButton {
    width: 220px;
    height: 35px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;
}

.HowToReceiveButton:hover {
    cursor: pointer;
    background-color: #cc3366;
}

@media only screen and (max-width:768px){
   
    .HowToReceiveContainer{
        padding: 45px 20px;
    }
    .HowToReceiveContent br{
        display: none;
    }

    .HowToReceiveContent h1{
        font-size: 2.5rem;
    }
}

@media only screen and (max-width:425px){
    .HowToReceiveContainer{
        padding: 45px 0px;
    }

    .HowToReceiveContent{
        padding: 45px 10px;
    }

    .HowToReceiveContent h1{
        font-size: 2.1rem;
    }

    .HowToReceiveContent p{
        font-size: .9rem;
        padding: 0 10px;
    }
    
    .HowToReceiveButton{
        width: 190px;
    }

    
}
.ReceiveIsEasyContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.ReceiveIsEasyLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ReceiveIsEasyLeft h1 {
    margin-bottom: 20px;
    color: #000066;
    
}

.ReceiveIsEasyLeft h2 {
    margin-bottom: 10px;
    color: #000066;
}

.ReceiveIsEasyLeft p {
    color: #333;
    line-height: 22px;
}

.ReceiveIsEasyRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.ReceiveIsEasyRight img {
    width: 360px;
}

@media only screen and (max-width:768px){
    .ReceiveIsEasyContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 800px;
        width: 100%;
        
    }

    .ReceiveIsEasyLeft{
        width: 90%;
        
    }

    .ReceiveIsEasyLeft p br{
        display: none;
    }

    .ReceiveIsEasyLeft h1,
    .ReceiveIsEasyLeft h2{
        text-align: center;
    }

    .ReceiveIsEasyLeft h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    
    .ReceiveIsEasyLeft{
        width: 90%;
        padding: 40px 0;
    }

    .ReceiveIsEasyRight{
        width: 100%;
        padding: 20px 0;
    }

    .ReceiveIsEasyRight img{
        width: 350px; 
            
    }
}

@media only screen and (max-width:375px){
    .ReceiveIsEasyRight img{
        width: 300px;     
    }

}
.SendYourDataContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.SendYourDataRight {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SendYourDataRight h1 {
    margin-bottom: 20px;
    color: #000066;
    
}

.SendYourDataRight h2 {
    margin-bottom: 10px;
    color: #000066;
}

.SendYourDataRight p {
    color: #333;
    line-height: 22px;
}

.SendYourDataLeft {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.SendYourDataLeft img {
    width: 360px;
}


@media only screen and (max-width:768px){
    .SendYourDataContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .SendYourDataRight{
        width: 90%;
    }

    .SendYourDataRight p br{
        display: none;
    }

    .SendYourDataRight h2{
        text-align: center;
    }

    .SendYourDataRight h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .SendYourDataRight{
        width: 90%;
        padding: 40px 0;
    }

    .SendYourDataLeft{
        width: 100%;
        padding: 20px 0;
    }
    .SendYourDataLeft img{
        width: 350px;     
    }
}

@media only screen and (max-width:375px){
    .SendYourDataLeft img{
        width: 300px;     
    }

}
.ReceiveInGMCContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.ReceiveInGMCLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ReceiveInGMCLeft h2 {
    margin-bottom: 10px;
    color: #000066;
}

.ReceiveInGMCLeft p {
    color: #333;
    line-height: 22px;
}

.ReceiveInGMCRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.ReceiveInGMCRight img {
    width: 400px;
}

@media only screen and (max-width:768px){
    .ReceiveInGMCContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .ReceiveInGMCLeft{
        width: 90%;
    }

    .ReceiveInGMCLeft p br{
        display: none;
    }

    .ReceiveInGMCLeft h2{
        text-align: center;
    }

    .ReceiveInGMCLeft h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .ReceiveInGMCLeft{
        width: 90%;
        padding: 40px 0;
    }

    .ReceiveInGMCRight{
        width: 100%;
        padding: 20px 0;
    }
    .ReceiveInGMCRight img{
        width: 350px;     
    }
}

@media only screen and (max-width:375px){
    .ReceiveInGMCRight img{
        width: 300px;     
    }

}
.YourFundsInGMCContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.YourFundsInGMCRight {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.YourFundsInGMCRight h1 {
    margin-bottom: 20px;
    color: #000066;
    
}

.YourFundsInGMCRight h2 {
    margin-bottom: 10px;
    color: #000066;
}

.YourFundsInGMCRight p {
    color: #333;
    line-height: 22px;
}

.YourFundsInGMCLeft {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.YourFundsInGMCLeft img {
    width: 450px;
}

@media only screen and (max-width:768px){
    .YourFundsInGMCContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .YourFundsInGMCRight{
        width: 90%;
    }

    .YourFundsInGMCRight p br{
        display: none;
    }

    .YourFundsInGMCRight h2{
        text-align: center;
    }

    .YourFundsInGMCRight h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .YourFundsInGMCRight{
        width: 90%;
        padding: 40px 0;
    }

    .YourFundsInGMCLeft{
        width: 100%;
        padding: 20px 0;
    }
    .YourFundsInGMCLeft img{
        width: 350px;     
    }
}

@media only screen and (max-width:375px){
    .YourFundsInGMCLeft img{
        width: 300px;     
    }

}
.WithdrawGMCContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 450px;
}

.WithdrawGMCLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.WithdrawGMCLeft h2 {
    margin-bottom: 10px;
    color: #000066;
}

.WithdrawGMCLeft p {
    color: #333;
    line-height: 22px;
}

.WithdrawGMCRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.WithdrawGMCRight img {
    width: 400px;
}

@media only screen and (max-width:768px){
    .WithdrawGMCContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        
    }

    .WithdrawGMCLeft{
        width: 90%;
    }

    .WithdrawGMCLeft p br{
        display: none;
    }

    .WithdrawGMCLeft h2{
        text-align: center;
    }

    .WithdrawGMCLeft h2{
        padding: 10px;
    }
}

@media only screen and (max-width:425px){
    .WithdrawGMCLeft{
        width: 90%;
        padding: 40px 0;
    }

    .WithdrawGMCRight{
        width: 100%;
        padding: 20px 0;
    }
    .WithdrawGMCRight img{
        width: 350px;     
    }
}

@media only screen and (max-width:375px){
    .WithdrawGMCRight img{
        width: 300px;     
    }

}
.containerWeHelpYou {
    background-image: url(/static/media/background_faq.c9225e53.png);
    background-color: #000066;
    height: 400px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 auto;
}
.contentWeHelpYou {
    height: 150px;
    padding: 45px;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.containerWeHelpYou h1{
    margin: 45px;
    font-size: 36px;
}

.containerWeHelpYou input {
    padding: 5px;
    width: 500px;
    height: 25px;
    color: white;   
    border-radius: 10px;
    border: 1px solid white;
    background-color: transparent;
}

@media only screen and (max-width:600px){
    .containerWeHelpYou{
        height: 630px;
    }
    
    .containerWeHelpYou input{
        width: 90%;
        font-size: .6rem ;
        text-align: center;
    }

    .contentWeHelpYou {
        height: 350px;
    }
}
.faqMainContainer {
    padding: 40px 100px 40px 100px;
}
.faqMainContent {
    width: 600px;
    
}

.faqMainContent h1 {
    margin-bottom: 20px;
    color: #cc0057;
}

@media only screen and (max-width:768px){
    .faqMainContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
        height: auto; 
        width: 100%;
        padding: 40px 0;
    }
    .faqMainContent h1{
        text-align: center;
    }
    
    
 
 }
 
 @media only screen and (max-width:425px){
     .faqMainContainer{
         height: auto;
     }
     .faqMainContent{
         width: 95%;
         margin-bottom: 100px;
     }
 
     .faqMainContent p{
         text-indent: 1.5em;
         letter-spacing: -1px;
     }
 
     
 }
.UnitePeopleContainer {
    padding: 100px 100px 80px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.UnitePeopleContent {
    padding: 45px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.UnitePeopleContent h1 {
    font-size: 50px;
    margin-bottom: 20px;
    color: #cc0057;
}

.UnitePeopleContent p {
    line-height: 25px;
    text-align: center;
}

@media only screen and (max-width:768px){
    .UnitePeopleContainer{
        padding: 50px;
    }
}

@media only screen and (max-width:520px){
    .UnitePeopleContainer{
        padding: 30px;
    }

    .UnitePeopleContent{
        padding: 45px 0;
    }

    .UnitePeopleContent br{
        display: none;
    }

    .UnitePeopleContent h1{
        font-size: 2.5rem;
    }
}

@media only screen and (max-width:320px){
    .UnitePeopleContainer{
        padding: 30px 20px;
    }

    .UnitePeopleContent h1{
        font-size: 2.3rem;
    }

    .UnitePeopleContent p{
        font-size: 15px;
    }
}
.FinancialAreaContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 400px;
    margin-bottom: 70px;
    
}

.FinancialAreaLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.FinancialAreaLeft h1 {
    margin-bottom: 20px;
    color: #000066;
    font-size: 3em;
    
    
}

.FinancialAreaLeft p {
    color: #333;
    line-height: 22px;
}

.FinancialAreaRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.FinancialRight img {
    width: 400px;
}

@media only screen and (max-width:768px){
    .FinancialAreaContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 650px;
        width: 100%;
        
    }

    .FinancialAreaLeft{
        width: 90%;
    }

    .FinancialAreaLeft  br{
        display: none;
    }

    .FinancialAreaLeft h1,
    .FinancialAreaLeft p{
        text-align: center;
    }

}

@media only screen and (max-width:520px){
    .FinancialAreaContainer{
        height: 600px;
    }
    .FinancialAreaLeft{
        width: 90%;
        padding: 30px;
    }

    .FinancialAreaLeft h1{
        font-size: 40px;
    }
    .FinancialAreaRight img{
        width: 350px;     
    }

    .FinancialAreaRight{
        width: 100%;
        padding: 20px 0;
    }
}

@media only screen and (max-width:375px){
    .FinancialAreaContainer{
        height: 550px;
    }
    .FinancialAreaRight img{
        width: 300px;     
    }

    .FinancialAreaLeft{
        padding: 30px 15px;
    }

    .FinancialAreaLeft h1{
        font-size: 35px;
    }

}
.YouCanContainer {
    background-image: url(/static/media/voce_pode_ser_global.45dfc2a2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    margin: auto;
    height: 500px;
    width: 1100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.YouCanContentLeft {
    text-align: left;
    
} 

.YouCanContentRight {
    width: 600px;
}

.YouCanContentLeft h1 {
    color: #fff;
    font-size: 2.6em;
    padding-bottom: 30px;
    
}


.YouCanBtn {
    width: 170px;
    height: 30px;
    border-radius: 20px;
    font-size: 16px;
    background-color: #cc0057;
    border: none;
    color: white;

}

.YouCanBtn:hover {
    cursor: pointer;
    background-color: #cc3366;
}

.LearnMore p {
    text-align: center;
    margin: 20px;
    color: #333;
    
    
}

.LearnMore a {
    color: black;
}

@media only screen and (max-width:1100px){
    .YouCanContainer{
        background-size: 100%;
        width: 100%;
    }
}

@media only screen and (max-width:900px){
    .YouCanContentLeft{
        margin: 0 50px ;
    }

    .YouCanContentLeft br{
        display: none;
    }

    .YouCanContentLeft{
        width: 300px;
    }
}

@media only screen and (max-width:768px){
    .YouCanContainer{
        height: 400px;
    }
    .YouCanContentLeft h1{
        font-size: 2rem;
        width: 230px;
    }
}


@media only screen and (max-width:425px){
    .YouCanContainer{
        height: 260px;
    }
    .YouCanContentLeft h1{
        font-size: 2rem;
        width: 230px;
    }
}

@media only screen and (max-width:375px){
    .YouCanContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .YouCanContentLeft{
        margin-right: 15px;
        width: 200px;
    }
    .YouCanContentLeft h1{
        font-size: 1.8rem;
        width: 200px;
    }

    .LearnMore p{
        margin-top: 0px;
    }
}

@media only screen and (max-width:320px){
    .YouCanContainer{
        height: 200px;
    }
}
.TogetherContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 600px;
    margin-bottom: 70px;
    
}

.TogetherLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.TogetherLeft h1 {
    margin-bottom: 20px;
    color: #cc0057;
    font-size: 3em;
    
    
}

.TogetherLeft p {
    color: #333;
    line-height: 25px;
}

.TogetherRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.TogetherRight img {
    width: 630px;
}

@media only screen and (max-width:768px){
    .TogetherContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 850px;
        width: 100%;
        
    }

    .TogetherLeft{
        width: 90%;
    }

    .TogetherLeft  br{
        display: none;
    }

    .TogetherLeft h1,
    .TogetherLeft p{
        text-align: center;
    }

    .TogetherRight{
        width: 90%;
    }

    .TogetherRight img{
        padding-left: 10px;
    }

}

@media only screen and (max-width:520px){
    .TogetherContainer{
        height: 750px;
    }
    .TogetherLeft{
        width: 90%;
        padding: 0px 10px 30px 10px;
    }

    .TogetherLeft h1{
        font-size: 40px;
    }
    .TogetherRight img{
        width: 350px;     
    }

    .TogetherRight{
        width: 100%;
        padding: 20px 0;
    }
}

@media only screen and (max-width:375px){
    
    .TogetherRight img{
        width: 315px;     
    }


    .TogetherLeft h1{
        font-size: 35px;
    }

}
.btnLanguageSelectionContainer {
    margin: 0 10px;
    padding-top: 10px;
    max-width: 150px;
    position: absolute !important;
    right: 50px;
}

.btnLanguageSelectionContainer button {
    color: #000066;
    border: thin solid #000066;
    padding: 0 10px;
}

.btnLanguageSelectionContainer button::after {
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000066;
}

.btnLanguageSelectionContainer button[aria-expanded="true"]:after {
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #4d4d4d;
}

