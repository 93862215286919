.TogetherContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 600px;
    margin-bottom: 70px;
    
}

.TogetherLeft {
    width: 500px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.TogetherLeft h1 {
    margin-bottom: 20px;
    color: #cc0057;
    font-size: 3em;
    
    
}

.TogetherLeft p {
    color: #333;
    line-height: 25px;
}

.TogetherRight {
    width: 500px;
    padding: 20px;
    align-items: center;
    text-align: center;
}

.TogetherRight img {
    width: 630px;
}

@media only screen and (max-width:768px){
    .TogetherContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 850px;
        width: 100%;
        
    }

    .TogetherLeft{
        width: 90%;
    }

    .TogetherLeft  br{
        display: none;
    }

    .TogetherLeft h1,
    .TogetherLeft p{
        text-align: center;
    }

    .TogetherRight{
        width: 90%;
    }

    .TogetherRight img{
        padding-left: 10px;
    }

}

@media only screen and (max-width:520px){
    .TogetherContainer{
        height: 750px;
    }
    .TogetherLeft{
        width: 90%;
        padding: 0px 10px 30px 10px;
    }

    .TogetherLeft h1{
        font-size: 40px;
    }
    .TogetherRight img{
        width: 350px;     
    }

    .TogetherRight{
        width: 100%;
        padding: 20px 0;
    }
}

@media only screen and (max-width:375px){
    
    .TogetherRight img{
        width: 315px;     
    }


    .TogetherLeft h1{
        font-size: 35px;
    }

}