@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';

}

html, body {
    background-color: aliceblue;
    -webkit-font-smoothing: antialiased !important;
}


.gmc-label {
    font-weight: bold;
    color: #000066;
    font-size: 14px;
}

.gmc-input {
    margin-top: 5px;
    background-color: transparent;
    border: 1px rgba(0, 0, 102, 0.6) solid;
    border-radius: 8px;
    color: #000066;
    padding: 4px 4px 4px 10px;
    outline: none;
}

.btnDefault {
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    background-color: #cc0057;
    border: none;
    color: white;
}

.btnDefaultOutlined {
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    background-color: transparent;
    color: #000066;
    border: 1px solid #000066;
}

.btnDefaultOutlinedActive {
    background-color: #cc0057;
    color: #FFF;
    border: none;
}

.btnDefault:last-of-type {
    margin-left: 10px;
}

.btnLink {
    color: #000066;
    font-size: 16px;
    border: 1px solid #000066;
    border-radius: 20px;
    padding: 5px 15px;
}