.side-drawer{
    height: 100%;
    background: #000066;
    box-shadow: 2px -20px 20px 1px #00006648;
    position: fixed;
    top: 100px;
    left: 0;
    width: 60%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer ul{
    height: 80%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
    
}

.side-drawer li{
    margin: 1rem 2rem;
}

.side-drawer a{
    color: #fff;
    text-decoration: none;
    

}

.side-drawer a:hover,
.side-drawer a:active{
    color: #cc0057;
    font-weight: 700;
}

@media only screen and (max-width:425px){
    .side-drawer{
        width: 50%;
    }
}

@media only screen and (max-width:375px){
    .side-drawer{
        width: 60%;
    }
}

@media only screen and (min-width: 869px){
    .side-drawer{
        display: none;
    }
}



